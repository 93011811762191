<template>
  <ul class="menu-nav">
    <router-link
      v-if="hasPermission('dashboard.read')"
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>
    <li
      v-if="
        (hasRole('super-admin') && hasPermission('users.read')) ||
        (hasRole('super-admin') && hasPermission('roles.read')) ||
        (hasRole('super-admin') && hasPermission('permissions.read')) ||
        (hasRole('super-admin') &&
          hasPermission('users.read') &&
          hasPermission('roles.read') &&
          hasPermission('permissions.read'))
      "
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/team-management') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-layers-2"></i>
        <span class="menu-text">Team Management</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Team Management</span>
            </span>
          </li>

          <router-link
            v-if="hasPermission('users.read')"
            to="/team-management/team-members"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Team Members</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="hasPermission('roles.read')"
            to="/team-management/roles"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Roles</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="hasPermission('permissions.read')"
            to="/team-management/permissions"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Permissions</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <router-link
      v-if="hasPermission('books.read')"
      to="/books-management"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-2"></i>
          <span class="menu-text">Books Management</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="hasPermission('series.read')"
      to="/series-management"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-2"></i>
          <span class="menu-text">Series Management</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="hasPermission('sell_sheets.read')"
      to="/sell-sheets"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-2"></i>
          <span class="menu-text">Sell Sheets Management</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="hasPermission('authors.read')"
      to="/authors-management"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-2"></i>
          <span class="menu-text">Authors Management</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="hasPermission('categories.read')"
      to="/categories"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-2"></i>
          <span class="menu-text">Categories Management</span>
        </a>
      </li>
    </router-link>
    <li
      v-if="
        (hasRole('editor') && hasPermission('filters.read')) ||
        (hasRole('editor') && hasPermission('filtervalues.read')) ||
        (hasRole('editor') &&
          hasPermission('filters.read') &&
          hasPermission('filtervalues.read'))
      "
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/filters-management'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-layers-2"></i>
        <span class="menu-text">Filters Management</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Filters Management</span>
            </span>
          </li>
          <router-link
            v-if="hasPermission('filters.read')"
            to="/filters-management/filters"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Filters</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="hasPermission('filtervalues.read')"
            to="/filters-management/filter-values"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Filter Values</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
      v-if="hasRole('editor') && hasPermission('pages.read')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/content-management'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-layers-2"></i>
        <span class="menu-text">Content Management</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Content Management</span>
            </span>
          </li>
          <router-link
            v-if="hasPermission('banners.read')"
            to="/content-management/banners"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Banners</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="hasPermission('homepagesettings.read')"
            to="/content-management/home-page"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Home page</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="hasPermission('menus.read')"
            to="/content-management/menus"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Menus</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="hasPermission('pages.read')"
            to="/content-management/pages"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pages</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="hasPermission('faqs.read')"
            to="/content-management/faqs"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Faqs</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <!--    <router-link-->
    <!--        v-if="hasPermission('redirects.read')"-->
    <!--        to="/redirects"-->
    <!--        v-slot="{ href, navigate, isActive, isExactActive }"-->
    <!--    >-->
    <!--      <li-->
    <!--          aria-haspopup="true"-->
    <!--          data-menu-toggle="hover"-->
    <!--          class="menu-item"-->
    <!--          :class="[-->
    <!--          isActive && 'menu-item-active',-->
    <!--          isExactActive && 'menu-item-active'-->
    <!--        ]"-->
    <!--      >-->
    <!--        <a :href="href" class="menu-link" @click="navigate">-->
    <!--          <i class="menu-icon flaticon2-layers-2"></i>-->
    <!--          <span class="menu-text">301 - Redirects</span>-->
    <!--        </a>-->
    <!--      </li>-->
    <!--    </router-link>-->
    <li
      v-if="hasRole('editor') && hasPermission('sitesettings.read')"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/settings') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-layers-2"></i>
        <span class="menu-text">Settings</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Settings</span>
            </span>
          </li>
          <router-link
            v-if="hasPermission('sitesettings.read')"
            to="/settings/site-settings"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Site Settings</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="hasPermission('adminsettings.read')"
            to="/settings/admin-settings"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Admin Settings</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <router-link
        v-if="hasPermission('inquiries.read')"
        to="/inquiries"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-2"></i>
          <span class="menu-text">Inquiries</span>
        </a>
      </li>
    </router-link>
    <router-link
        v-if="hasPermission('pages.read')"
        to="/email-templates"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-layers-2"></i>
          <span class="menu-text">Email Templates</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
