<template>
  <v-dialog
    persistent
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar :color="options.color" dark dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">{{ message }}</v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn @click.native="agree" color="primary darken-1" text>Yes</v-btn>
        <v-btn @click.native="cancel" color="grey" text>Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    dialog: false,
    message: "",
    callback: null,
    title: "",
    options: {
      color: "",
      width: 290,
      zIndex: 200
    }
  }),
  computed: mapState(["confirmation"]),
  created: function() {
    this.$store.watch(
      state => state.confirmation.confirm,
      () => {
        const msg = this.$store.state.confirmation.confirm.msg;
        if (msg !== "") {
          this.options.color = this.$store.state.confirmation.confirm.clr;
          this.open(
            this.$store.state.confirmation.confirm.title,
            this.$store.state.confirmation.confirm.msg,
            this.options,
            this.$store.state.confirmation.confirm.callback
          );
        }
      }
    );
  },
  methods: {
    open(title, message, options, callback) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.callback = callback;
      this.options = Object.assign(this.options, options);
    },
    agree() {
      this.dialog = false;
      if (typeof this.callback == "function") this.callback();
    },
    cancel() {
      this.dialog = false;
    }
  }
};
</script>
