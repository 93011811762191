<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ this.$store.state.auth.user.name }}
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <!--      <div-->
      <!--        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"-->
      <!--      >-->
      <!--        <h3 class="font-weight-bold m-0">-->
      <!--          User Profile-->
      <!--          <small class="text-muted font-size-sm ml-2">12 messages</small>-->
      <!--        </h3>-->
      <!--        <a-->
      <!--          href="#"-->
      <!--          class="btn btn-xs btn-icon btn-light btn-hover-primary"-->
      <!--          id="kt_quick_user_close"-->
      <!--        >-->
      <!--          <i class="ki ki-close icon-xs text-muted"></i>-->
      <!--        </a>-->
      <!--      </div>-->
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="picture" alt="" />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <a
              href="#"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ this.$store.state.auth.user.name }}
            </a>
            <!--            <div class="text-muted mt-1">Application Developer</div>-->
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ this.$store.state.auth.user.email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Sign out
            </button>
          </div>
        </div>
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <div class="navi-link">
            <div class="navi-text">
              <button
                class="btn btn-light-primary btn-bold"
                @click="shCpDialog()"
              >
                Change Password
              </button>
            </div>
          </div>
          <!--end:Item-->
        </div>
        <!--end::Header-->
        <!--end::Notifications-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { DO_LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import { bus } from "@/main";

export default {
  name: "KTQuickUser",
  data() {
    return {};
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store.dispatch(DO_LOGOUT).then(data => {
        if (data._metadata.outcomeCode === 0) {
          this.$router.push({ name: "login" });
        }
      });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    shCpDialog() {
      bus.$emit("cPDialogOpen", true);
    }
  },
  computed: {
    picture() {
      return process.env.BASE_URL + "media/users/default.jpg";
    }
  }
};
</script>
