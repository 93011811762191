<template>
  <v-dialog persistent v-model="changePasswordDialog" max-width="500px">
    <v-card>
      <v-form
        id="change_password_form"
        v-on:submit.prevent="hitChangePassword"
        ref="changePasswordForm"
        v-model="validationRules.valid"
        lazy-validation
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                :counter="20"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                name="password"
                v-model="validationRules.password"
                :rules="validationRules.passwordRules"
                label="Current Password"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12">
              <v-text-field
                :counter="20"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                name="new_password"
                v-model="validationRules.newPassword"
                :rules="validationRules.newPasswordRules"
                label="New Password"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12">
              <v-text-field
                :counter="20"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
                name="new_password_confirmation"
                v-model="validationRules.newPasswordConfirmation"
                :rules="
                  validationRules.newPasswordConfirmationRules.concat(
                    passwordConfirmationRule
                  )
                "
                label="Confirm Password"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="shCpDialog()">Cancel</v-btn>
          <v-btn
            type="submit"
            :disabled="!validationRules.valid"
            color="blue darken-1"
            text
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-col cols="12">
        <div
          v-if="errors && errors != undefined && errors.length >= 0"
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { bus } from "@/main";
import {
  CHANGE_PASSWORD,
  CLEAR_AUTH_ERRORS
} from "@/core/services/store/auth.module";

export default {
  data: () => ({
    changePasswordDialog: false,
    show: false,
    show1: false,
    show2: false,
    validationRules: {
      valid: true,
      password: "",
      passwordRules: [
        v => !!v || "Current password is required",
        v =>
          (v && v.length <= 20) || "Password must be less than 20 characters",
        v => (v && v.length >= 6) || "Password must be at least 6 characters"
      ],
      newPassword: "",
      newPasswordRules: [
        v => !!v || "New password is required",
        v =>
          (v && v.length <= 20) || "Password must be less than 20 characters",
        v => (v && v.length >= 6) || "Password must be at least 6 characters"
      ],
      newPasswordConfirmation: "",
      newPasswordConfirmationRules: [v => !!v || "Type confirm password"]
    }
  }),
  mounted() {
    bus.$on("cPDialogOpen", data => {
      this.changePasswordDialog = data;
    });
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    passwordConfirmationRule() {
      return () =>
        this.validationRules.newPassword ===
          this.validationRules.newPasswordConfirmation || "Password must match";
    }
  },
  watch: {
    changePasswordDialog(val) {
      !val && this.$store.dispatch(CLEAR_AUTH_ERRORS);
    }
  },
  methods: {
    shCpDialog() {
      this.changePasswordDialog = !this.changePasswordDialog;
      this.$refs.changePasswordForm.reset();
    },
    hitChangePassword() {
      if (this.$refs.changePasswordForm.validate()) {
        let formData = new FormData(
          document.getElementById("change_password_form")
        );
        const data = {};
        // need to convert it before using not with XMLHttpRequest
        for (let [key, val] of formData.entries()) {
          Object.assign(data, { [key]: val });
        }
        this.$store.dispatch(CLEAR_AUTH_ERRORS);
        this.$store.dispatch(CHANGE_PASSWORD, data).then(() => {
          this.shCpDialog();
        });
      }
    }
  }
};
</script>
